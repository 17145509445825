.faq-section {
	background: #fdfdfd;
	min-height: 75vh;
	padding: 10vh 0 0;
    .container{
        max-width: torem(1000);
    }
.faq-title {
	h2 {
		position: relative;
		margin-bottom: 45px;
		display: inline-block;
		font-weight: 600;
		line-height: 1;
		&::before {
			content: "";
			position: absolute;
			left: 50%;
			width: 60px;
			height: 2px;
			background: #000;
			bottom: -25px;
			margin-left: -30px;
		}
	}
	p {
		padding: 0 190px;
		margin-bottom: 10px;
	}
}
.faq {
	background: #FFF;
	box-shadow: 0 2px 48px 0 rgba(44, 56, 69, 0.06);
	border-radius: 4px;
	.card {
		border: none;
		background: none;
		border-bottom: 1px dashed #CEE1F8;
        &:last-child{
            border-bottom: none;
        }
		.card-header {
			padding: 0;
			border: none;
			background: none;
			-webkit-transition: all 0.3s ease 0s;
			-moz-transition: all 0.3s ease 0s;
			-o-transition: all 0.3s ease 0s;
			transition: all 0.3s ease 0s;
			&:hover {
				background: rgba(75, 126, 239, 0.1);
			}
			.faq-title {
				width: 100%;
				text-align: left;
                padding: 30px 30px 15px;
				font-weight: 400;
				font-size: 15px;
				letter-spacing: 1px;
				color: #3B566E;
				text-decoration: none !important;
				-webkit-transition: all 0.3s ease 0s;
				-moz-transition: all 0.3s ease 0s;
				-o-transition: all 0.3s ease 0s;
				transition: all 0.3s ease 0s;
                display: flex;
                align-items: center;
				cursor: pointer;
				padding-top: torem(20);
				padding-bottom: torem(20);
				&:hover {
					font-weight: 500;
				}
				.badge {
					width: torem(22);
					height: torem(22);
					display: flex;
                    align-items: center;
                    justify-content: center;
					-webkit-border-radius: 100px;
					-moz-border-radius: 100px;
					border-radius: 100px;
					text-align: center;
					background: #000;
					color: #fff;
					font-size: 12px;
					margin-right: 20px;
                    padding-left: torem(9);
				}
			}
		}
		.card-body {
			padding: 30px 30px 15px;
			font-weight: 400;
			font-size: 16px;
			color: #6F8BA4;
			line-height: 28px;
			letter-spacing: 1px;
			border-top: 1px solid #F3F8FF;
			p {
				margin-bottom: 14px;
			}
		}
	}
}
}