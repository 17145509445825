.slider-hp {
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: torem(25);
    &.version2 {
        height: unset;
        .slider-hp--text {
            text-align: center;
            max-width: torem(800);
            margin: 0 auto;
            &-title {
                max-width: none;
                font-size: torem(65);
                line-height: torem(65);
                margin-bottom: torem(20);
            }
            &-subtitle {
                font-size: torem(30);
            }
            .btn-1 {
                padding: 5px 90px;
                font-size: torem(24);
                border-radius: 10px;
            }
        }
    }
    &--text {
        position: absolute;
        bottom: torem(78);
        z-index: 1;
        text-align: left;
        color: #fdfdfd;
        @include for-phone-only {
            text-align: center;
        }
        .btn {
            margin-top: torem(28);
        }
    }
    &--text-title {
        font-weight: 600;
        font-size: torem(120);
        text-shadow: 0 torem(5) torem(8) #000;
        max-width: torem(885);
        line-height: torem(110);
        margin-bottom: torem(30);
        @include for-phone-only {
            font-size: torem(60);
            line-height: torem(60);
        }
        @include for-tablet-portrait-up {
            font-size: torem(70);
            line-height: torem(50);
        }
    }
    &--text-subtitle {
        font-weight: 100;
        font-size: torem(35);
        text-shadow: 0 torem(5) torem(8) #000;
        margin-bottom: torem(18);
        @include for-phone-only {
            font-size: torem(28);
            line-height: torem(30);
        }
    }
    .btn-1 {
        padding: torem(10) torem(90);
        font-size: torem(40);
        font-weight: 300;
        @include for-phone-only {
            font-size: torem(28);
        }
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        .video-background {
            width: 100%;
            &.mobile-on {
                @include for-desktop-up {
                    display: none;
                }
            }
            &.desktop-on {
                @include for-phone-only {
                    display: none;
                }
            }
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: transparent
                    linear-gradient(180deg, #000000b3 0%, #0000 52%, #0006 100%)
                    0% 0% no-repeat padding-box;
            }
        }
        .btn-vol {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            border: 1px solid #fff;
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            z-index: 1000;
            font-size: 0;
            background-size: 60%;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: url(/assets/img/icon/audio-off.svg);
            opacity: 0.5;
            cursor: pointer;
            transition: opacity 0.15s ease-in-out;
            &.unmute {
                background-image: url(/assets/img/icon/audio-on.svg);
            }
        }
    }

    .swiper-slide picture {
        width: 100%;
        height: 100%;
        position: relative;
        &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: transparent
                linear-gradient(180deg, #000000b3 0%, #0000 52%, #0006 100%) 0%
                0% no-repeat padding-box;
        }
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            width: 20px;
            height: 4px;
            border-radius: 1px;
            background-color: #fff;
        }
    }
}
