.internalpage {
    section {
        padding-top: 125px;
        @include for-phone-only {
            padding-top: torem(25);
        }
        @include for-tablet-portrait-up {
            padding-top: torem(50);
        }
        .container {
            @include for-desktop-up {
                max-width: torem(1024);
            }
        }
        .paragraph {
            margin-bottom: torem(45);
            overflow: hidden;
            img {
                width: 100%;
            }
            a {
                font-weight: bold;
                color: #000;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .title-article {
            font-size: torem(38);
            font-weight: bold;
            margin-bottom: torem(20);
            text-align: center;
        }
        .title-section {
            text-align: left;
            font-size: torem(26);
            color: black;
            text-transform: none;
            font-weight: 600;
            margin-bottom: torem(15);
        }
        .text-section {
            text-align: left;
            padding: 0;
            font-size: torem(18);
            font-weight: 300;
            line-height: 1.8;
        }
    }
    .img__right {
        float: right;
        margin-left: 20px;
    }
    .img__left {
        float: left;
        margin-right: 20px;
    }
    .img__center {
        margin: 20px auto;
        text-align: center;
    }
}
