.navbar{
  position: fixed;
  width: 100%;
  background: $white;
  z-index: 999;
  height: auto;
  padding: torem(25) 0;

  @include for-tablet-landscape-up{
    background: transparent;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
  }
  .container{
    @include for-tablet-landscape-up{
      align-items: flex-start;
    }
  }
  .navbar-collapse{
    @include for-show-full-menu-sidebar-up{
      flex-basis: min-content !important;
    }
    &:last-child{
      justify-content: end;
    }
    .navbar-nav{
      gap: torem(20);
      &.main-menu{
        @include for-tablet-landscape-up{
          margin-right: torem(70);
        }
      }
      .nav-item{
        .dropdown-menu{
          padding: torem(15);
          min-width: torem(300);
          box-shadow: 0 torem(2) torem(48) 0 rgba(44, 56, 69, 0.06);
          border: 0;
          @include for-phone-only{
            background: transparent;
            box-shadow: none;
          }
          li{
            padding: torem(8) 0;
            border-bottom: torem(1) solid #ededed;
            @include for-phone-only{
              border-bottom: none;
              text-align: center;
            }
            a{
              color: #000;
              text-transform: uppercase;
              text-decoration: none;
              &:hover{
                font-weight: 500;
              }
            }
            &:last-child{
              border: none;
            }
          }
        }
        &.active{
          .nav-link{
            font-weight: bold;
            &::before{
              transform: unset;
            }
          }
        }
        .nav-link{
          font-size: torem(18);
          font-weight: 400;
          text-transform: uppercase;
          color: #fff;
          position: relative;
          &::before{
            content: "";
width: torem(15);
height: torem(4);
position: absolute;
left: torem(8);
bottom: 0;
background: #fff;
transition: 0.5s transform ease;
transform: scale3d(0,1,1);
transform-origin: 0 50%;
          }
          &:hover{
            font-weight: 500;
            transition: 1s transform ease;
            &::before{
              transform: scale3d(1,1,1);
            }
          }
          
        }
      }
      &.lang{
        .nav-item{
          .nav-link{
            font-weight: bold;
          }
        }
      }
    }
  }
  .navbar-brand{
    .logo{
      svg{
        width: torem(350);
        @include for-desktop-only{
          width: torem(310);
        }
        .cls-1{
          fill: $dark;
          @include for-tablet-landscape-up{
            fill: $white;
          }
        }
        &#desktop-logo{
          @include for-phone-only{
            display: none;
          }
        }
        &#mobile-logo{
          display: none;
          @include for-phone-only{
            display: block !important;
            width: torem(100);
          }
        }
      }
    }
    
  }
  &.scrolled{
    background-color: #fffc;
    background-image: none;
    .container{
      align-items: center;
    }
    .navbar-brand{
      .logo{
        svg{
          .cls-1{
            fill: $dark;
          }
          @include for-tablet-landscape-up{
            width: torem(300);
          }
        }
      }
    }
    .navbar-collapse{
      margin-top: 0;

    .navbar-nav{
      .nav-item{
        .nav-link{
          color: $dark;
          &::before{
            background: #000;
            
          }
        }
      }
    }
  }
  }
  &.full-menu{
    height: 100%;
    align-items: baseline;
    transition: height 1s;
    background-color: $white !important;
    .navbar-collapse{
      margin-top: torem(50) !important;
    .navbar-nav{
    .nav-item{
      text-align: center;
      .nav-link{
        color: $dark;
        &::before{
          background: $dark;
        }
        &:hover{
            &::before{
              transform: translate(-50%, 0);
              left: 50%;
            }
          }
         
      }
      &.active{
        .nav-link{
        &::before{
          transform: translate(-50%, 0);
      left: 50%;
        }
      }
      }
    }
  }
}
  }
.navbar-toggler {
	width: 25px;
	height: 20px;
	position: relative;
	transition: .5s ease-in-out;
	outline: none;
	box-shadow: none;
	border: 0;
	&:focus {
		outline: none;
		box-shadow: none;
		border: 0;
	}
	&:active {
		outline: none;
		box-shadow: none;
		border: 0;
	}
	span {
		margin: 0;
		padding: 0;
	}
	.top-bar {
		margin-top: 0;
		transform: rotate(135deg);
	}
	.middle-bar {
		opacity: 0;
		filter: alpha(opacity=0);
	}
	.bottom-bar {
		margin-top: 0;
		transform: rotate(-135deg);
	}
}
.navbar-toggler-icon {
	&:focus {
		outline: none;
		box-shadow: none;
		border: 0;
	}
}
.toggler-icon {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: $dark;
	border-radius: 20px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: .25s ease-in-out;
}
.middle-bar {
	margin-top: 0;
}
.navbar-toggler.collapsed {
	.top-bar {
		margin-top: -10px;
		transform: rotate(0deg);
	}
	.middle-bar {
		opacity: 1;
		filter: alpha(opacity=100);
	}
	.bottom-bar {
		margin-top: 10px;
		transform: rotate(0deg);
	}
	.toggler-icon {
		background: $dark;
	}
}

}
.detail{
  .navbar{
    background-color: #fffc;
    background-image: none;
    .container{
      align-items: center;
    }
    .navbar-nav{
      .nav-item{
        .nav-link{
          font-size: torem(18);
          font-weight: 400;
          text-transform: uppercase;
          color: #000;
          &::before{
            background: #000;
          }
        }
      }
    }
    .navbar-brand{
      .logo{
        svg{
          .cls-1{
            fill: #000;
          }
        }
      }
      
    }
    .navbar-collapse{
      margin-top: 0;
    }
  }
}
