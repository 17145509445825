body {
    font-family: 'Metropolis', sans-serif;
}
.row.video {
    justify-content: center;
}
.icon {
    width: torem(24);
    height: torem(24);
}
.title-large {
    font-size: torem(30);
    font-weight: 600;
}
.btn-1 {
    @include button-radius;
}
@media (min-width: 1200px) {
    .container {
        max-width: 1440px;
    }
}
section {
    padding: torem(40) 0;
}
.internalpage {
    .content {
        padding-top: torem(100);
    }
}
.detail {
    .title-page {
        margin: torem(30) 0 torem(50);
        text-align: center;
        .title-section {
            font-size: torem(60);
            font-weight: bold;
        }
        .lead {
            font-size: torem(18);
        }
    }
}
.anchor2 {
    display: block;
    height: 93px;
    margin-top: -93px;
    visibility: hidden;
}
