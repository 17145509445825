.card-hp{
  padding-left: torem(20);
  padding-right: torem(20);
  padding-top: torem(10);
  position: relative;
  padding-bottom: torem(45);
  .card-box{
    border-radius: torem(20);
    height: torem(425);
    display: flex;
    align-items: flex-end;
    padding: torem(10) 0;
    a{
      text-decoration: none;
      margin: 0 auto;
    }
    picture{
      position: absolute;
      top: torem(-10);
      left: torem(-10);
      width: 100%;
      img{
        width: 100%;
        border-radius: 20px;
        height: torem(375);
        object-fit: cover;
        box-shadow: 0 torem(3) torem(10) #0006;
      }
    }
  }
  &--text-subtitle {
    font-weight: bold;
    font-size: torem(32);
    color: $white;
    text-align: center;
  }
  .swiper-pagination{
    .swiper-pagination-bullet{
      width: torem(18);
      height: torem(5);
      border-radius: 0;
      &-active{
background-color: $dark;      }
    }
  }
}
