.roadmap {
    padding: 100px 0;
    .container {
        max-width: torem(1000);
    }
    .point {
        display: flex;
        flex-direction: column;
        padding: torem(25) torem(50);
        position: relative;

        &:first-child {
            .point-index {
                top: auto;
                bottom: 0;
                transform: translate(-50%, 2px) !important;
            }
        }

        &:last-child {
            .point-index {
                top: 0;
                transform: translate(50%, -2px) !important;
            }
        }

        &:not(:last-child) {
            border-bottom: 2px solid #4b7eef;
        }

        &:nth-child(odd) {
            align-items: flex-start;

            &:not(:first-child) {
                border-left: 2px solid #4b7eef;
            }

            .point-index {
                left: 0;
                transform: translate(-50%, -50%);
            }
        }

        &:nth-child(even) {
            align-items: flex-end;

            &:not(:last-child) {
                border-right: 2px solid #4b7eef;
            }

            .point-index {
                right: 0;
                transform: translate(50%, -50%);
            }
        }

        .point-index {
            position: absolute;
            top: 50%;
            width: 40px;
            height: 40px;
            background: #151317;
            border: 2px solid #4b7eef;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 23px;
            font-weight: bold;
            color: #fff;
        }

        .point-label {
            flex: 1 0 100%;
            width: 100%;
            font-size: torem(18);
            margin-bottom: torem(2);
            font-weight: 500;
            @media (min-width: 641px) {
                flex: 1 0 50%;
                width: 50%;
            }
        }

        .point-text {
            flex: 1 0 100%;
            width: 100%;
            font-size: torem(15);
            color: grey;
            line-height: 1.5;
            @media (min-width: 641px) {
                flex: 1 0 50%;
                width: 50%;
            }
        }
    }
}
.timeline {
    position: relative;
    width: torem(660);
    margin: 0 auto;
    margin-top: torem(20);
    padding: 1em 0;
    list-style-type: none;
    &::before {
        position: absolute;
        left: 50%;
        top: 0;
        content: ' ';
        display: block;
        width: torem(6);
        height: 100%;
        margin-left: torem(-3);
        background: rgb(80, 80, 80);
        background: -moz-linear-gradient(
            top,
            rgba(80, 80, 80, 0) 0%,
            rgb(80, 80, 80) 8%,
            rgb(80, 80, 80) 92%,
            rgba(80, 80, 80, 0) 100%
        );
        background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(0%, rgba(30, 87, 153, 1)),
            color-stop(100%, rgba(125, 185, 232, 1))
        );
        background: -webkit-linear-gradient(
            top,
            rgba(80, 80, 80, 0) 0%,
            rgb(80, 80, 80) 8%,
            rgb(80, 80, 80) 92%,
            rgba(80, 80, 80, 0) 100%
        );
        background: -o-linear-gradient(
            top,
            rgba(80, 80, 80, 0) 0%,
            rgb(80, 80, 80) 8%,
            rgb(80, 80, 80) 92%,
            rgba(80, 80, 80, 0) 100%
        );
        background: -ms-linear-gradient(
            top,
            rgba(80, 80, 80, 0) 0%,
            rgb(80, 80, 80) 8%,
            rgb(80, 80, 80) 92%,
            rgba(80, 80, 80, 0) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(80, 80, 80, 0) 0%,
            rgb(80, 80, 80) 8%,
            rgb(80, 80, 80) 92%,
            rgba(80, 80, 80, 0) 100%
        );
        z-index: 5;
    }
    li {
        padding: 1em 0;
        &::after {
            content: '';
            display: block;
            height: 0;
            clear: both;
            visibility: hidden;
        }
    }
}
.direction-l {
    position: relative;
    width: 300px;
    float: left;
    text-align: right;
    .flag {
        -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15),
            0 0 1px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15),
            0 0 1px rgba(0, 0, 0, 0.15);
        box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15),
            0 0 1px rgba(0, 0, 0, 0.15);
        &::before {
            position: absolute;
            top: 50%;
            right: -40px;
            content: ' ';
            display: block;
            width: torem(20);
            height: torem(20);
            margin-top: -10px;
            background: #fff;
            border-radius: 10px;
            border: 4px solid #4B7EEF;
            z-index: 10;
        }
        &::after {
            content: '';
            position: absolute;
            left: 100%;
            top: 50%;
            height: 0;
            width: 0;
            margin-top: -8px;
            border: solid transparent;
            border-left-color: rgb(248, 248, 248);
            border-width: 8px;
            pointer-events: none;
        }
    }
    .time-wrapper {
        float: left;
    }
}
.direction-r {
    position: relative;
    width: 300px;
    float: right;
    .flag {
        -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15),
            0 0 1px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15),
            0 0 1px rgba(0, 0, 0, 0.15);
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
        &::before {
            position: absolute;
            top: 50%;
            right: -40px;
            content: ' ';
            display: block;
            width: torem(20);
            height: torem(20);
            margin-top: -10px;
            background: #fff;
            border-radius: 10px;
            border: 4px solid #4B7EEF;
            z-index: 10;
            left: -40px;
        }
        &::after {
            content: '';
            position: absolute;
            right: 100%;
            top: 50%;
            height: 0;
            width: 0;
            margin-top: torem(-8);
            border: solid transparent;
            border-right-color: rgb(248, 248, 248);
            border-width: torem(8);
            pointer-events: none;
        }
    }
    .time-wrapper {
        float: right;
    }
    .desc {
        margin: 1em 0 0 0.75em;
    }
}
.flag-wrapper {
    position: relative;
    display: inline-block;
    text-align: center;
}
.flag {
    position: relative;
    display: inline;
    background: rgb(248, 248, 248);
    padding: torem(6) torem(10);
    border-radius: torem(5);
    font-weight: 600;
    text-align: left;
}
.time-wrapper {
    line-height: 1em;
    font-size: 0.66666em;
    color: #4B7EEF;
    vertical-align: middle;
}
.time {
    display: inline-block;
    padding: torem(4) torem(6);
    background: rgb(248, 248, 248);
}
.desc {
    margin: 1em 0.75em 0 0;
    font-size: 0.77777em;
    font-style: italic;
    line-height: 1.5em;
}
@media screen and (max-width: 660px) {
    .timeline {
        width: 100%;
        padding: 4em 0 1em 0;
        li {
            padding: 2em 0;
        }
    }
    .direction-l {
        float: none;
        width: 100%;
        text-align: center;
        .flag {
            &::before {
                position: absolute;
                top: -30px;
                left: 50%;
                content: ' ';
                display: block;
                width: torem(20);
                height: torem(20);
                margin-left: -9px;
                background: #fff;
                border-radius: 10px;
                border: 4px solid #4B7EEF;
                z-index: 10;
            }
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: -8px;
                height: 0;
                width: 0;
                margin-left: -8px;
                border: solid transparent;
                border-bottom-color: rgb(255, 255, 255);
                border-width: 8px;
                pointer-events: none;
            }
        }
        .time-wrapper {
            float: none;
        }
        .desc {
            position: relative;
            margin: 1em 1em 0 1em;
            padding: 1em;
            z-index: 15;
        }
    }
    .direction-r {
        float: none;
        width: 100%;
        text-align: center;
        .flag {
            &::before {
                position: absolute;
                top: -30px;
                left: 50%;
                content: ' ';
                display: block;
                width: torem(20);
            height: torem(20);
                margin-left: -9px;
                background: #fff;
                border-radius: 10px;
                border: 4px solid #4B7EEF;
                z-index: 10;
            }
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: -8px;
                height: 0;
                width: 0;
                margin-left: -8px;
                border: solid transparent;
                border-bottom-color: rgb(255, 255, 255);
                border-width: 8px;
                pointer-events: none;
            }
        }
        .time-wrapper {
            float: none;
        }
        .desc {
            position: relative;
            margin: 1em 1em 0 1em;
            padding: 1em;
            z-index: 15;
        }
    }
    .flag-wrapper {
        text-align: center;
    }
    .flag {
        background: rgb(255, 255, 255);
        z-index: 15;
    }
    .time-wrapper {
        display: block;
        position: relative;
        margin: 4px 0 0 0;
        z-index: 14;
    }
    .desc {
        position: relative;
        margin: 1em 0 0 0;
        padding: 1em;
        background: rgb(245, 245, 245);
        -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        z-index: 15;
    }
}

