.nft {
    .nft-box {
        text-align: center;
        gap: torem(45);
        display: flex;
        flex-direction: column;
        max-width: torem(830);
        @include for-phone-only {
            gap: torem(25);
        }
        .title-box {
            font-size: torem(40);
            line-height: torem(40);
            font-weight: bold;
            margin: 0 auto;
            @include for-phone-only {
                font-size: torem(30);
            }
        }
        .text-box {
            font-size: torem(32);
            line-height: torem(42);
            @include for-phone-only {
                font-size: torem(22);
                line-height: torem(32);
            }
            @include for-tablet-portait-only {
                font-size: torem(22);
                line-height: torem(32);
            }
        }
    }
}
