.banner-countdown{
    padding-bottom: torem(50);
    .banner{
        height: torem(300);
        overflow: hidden;
        position: relative;
        border-radius: torem(20);
        @include for-phone-only{
            height: torem(550);
            background-color: #0E1525;
          }
          @include for-tablet-portait-only{
            height: torem(500);
        }
        picture{
            position: relative;
            @include for-tablet-portait-only{
            &::before{
                content: "";
                position: absolute;
                background: #000;
                width: 100%;
                height: 100vh;
                opacity: .4;
            }
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left;
        }
        @include for-phone-only{
            display: block;
            height: 50%;
            &::before{
                content: url(/assets/img-editoriali/banner-countdown-m-shadow.svg);
                position: absolute;
                bottom: torem(-50);
            }
        }
    }
        .main{
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: torem(35);
            @include for-phone-only{
                right: 50%;
                transform: translate(50%, 0);
                top: unset;
                bottom: torem(35);
              }
              @include for-tablet-portait-only{
                right: 50%;
                transform: translate(50%, 0);
                top: unset;
                bottom: torem(35);
              }
            .title-countdown{
                font-size: torem(40);
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                max-width: torem(300);
                margin: 0 auto;
                color: $white;
                line-height: torem(34);
                margin-bottom: torem(15);
                @include for-phone-only{
                    font-size: torem(28);
                  }
            }
        .countdown{
            display: flex;
            padding: torem(20) torem(60);
            border: 2px solid $white;
            border-radius: torem(20);
            justify-content: space-between;
            gap: torem(60);
            @include for-phone-only{
                gap: torem(40);
                padding: torem(16) torem(40);
              }
            .item{
                color: $white;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                .int{
                    font-size: torem(16);
                    font-weight: 300;

                }
                .count_down{
                    font-size: torem(50);
                    font-weight: 100;
                    line-height: torem(45);
                    @include for-phone-only{
                        font-size: torem(35);
                        font-weight: 500;
                      }
                      @include for-tablet-portait-only{
                        font-weight: 400;
                      }
                }
                &::after{
                    content: ":";
                    font-size: torem(50);
                    position: absolute;
                    opacity: .4;
                    font-weight: 100;
                    right: torem(-35);
                    top: 50%;
                    transform: translate(0, -55%);
                    @include for-phone-only{
                       right: torem(-25);
                      }
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
    }
    .btn-part{
        position: relative;
        .btn-countdown{
            background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 torem(3) torem(10) #0006;
    border-radius: torem(12);
    color: #6472B1;
    @include for-desktop-up{
        font-size: torem(24);
    }
        }
    }
}