@mixin button-radius {
  padding: torem(10) torem(50);
  border-radius: torem(20);
  text-transform: uppercase;
  font-weight: 500;
  display: inline-flex;
  text-decoration: none;
  font-size: torem(24);
  &:hover{
    background: $white;
    color: $dark;
    cursor: pointer;
    transition: ease 0.5 s;
  }
  @include for-phone-only{
    font-size: torem(18);
  }
}
@mixin button-bg($bg) {
  border: 2px solid $bg;

  &:hover {
    background: darken($bg, 8%);
    transition: all 0.3s ease;
  }

  &:active {
    background: darken($bg, 25%);
  }
}

.btn-dark {
  @include button-bg(#000);

  color: #000;
}

.btn-light {
  @include button-bg(#fff);

  color: #fff;
}
.btn-green {
  @include button-bg(#dedede);

  color: #000;
}

