.card-detail {
  padding: 0 torem(15) torem(50);

  .load {
    text-align: center;

    .btn-1 {
      margin: torem(25) auto;
    }
  }

  .card-container {
    display: flex;
    gap: torem(60);
    flex-wrap: wrap;
    justify-content: center;

    .card-box {
      background-color: #d0d0d0;
      border-radius: torem(25);
      flex: 0 0 calc(33% - 37px);
      display: none;
      width: 100%;

      @include for-tablet-portrait-up {
        flex: 0 0 calc(50% - 30px);
      }

      @include for-tablet-landscape-only {
        flex: 0 0 calc(50% - 30px);
      }

      @include for-phone-only {
        flex: 0 0 100%;
      }

      &.display {
        display: block;
      }

      .box-img {
        position: relative;
        width: calc(100% + 30px);
        left: -15px;
        box-shadow: 0 torem(3) torem(10) #0004;
        border-radius: torem(25);
        height: torem(330);
        overflow: hidden;

        .badge {
          &.blue {
            background: darkblue;
          }

          &.red {
            background: darkred;
          }

          &.green {
            background: darkgreen;
          }

          &.diagonal {
            z-index: 1;
            position: absolute;
            padding: 10px 70px;
            min-width: 100px;
            transform: rotate(-45deg) translate(-37%, 0);
            color: white;
            text-align: center;
            text-transform: uppercase;
            font-size: 12px;
            top: -20px;
            box-sizing: border-box;
          }
        }

        .title-part {
          .price {
            font-size: torem(16);
            color: #efefef;

            // &.matic {
            //     font-size: torem(20);
            //     margin-bottom: torem(2);
            // }
            &.eur {
              font-size: torem(20);
              margin-bottom: torem(2);
            }
          }
        }

        .rarity {
          text-transform: uppercase;
          color: #fff;
          border-radius: torem(5);
          font-size: torem(15);
          font-weight: 500;

          &.gold {
            background: #af9500;
          }

          &.silver {
            background: #d7d7d7;
            color: #000;
          }

          &.bronze {
            background: #6a3805;
          }
        }

        .price-part {
          display: flex;
          align-items: end;
          flex-direction: column;
          flex: 40%;

          .card-detail--text-subtitle {
            font-size: torem(20);
          }
        }

        .price-sec {
          display: flex;
          gap: 20px;
          align-items: center;
          margin-top: torem(8);
        }

        img {
          width: 100%;
          border-radius: torem(25);
          height: 100%;
          object-fit: cover;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent linear-gradient(179deg, #1d1d1d00 0%, #1d1d1d 100%) 0% 0% no-repeat padding-box;
          border-radius: torem(25);
        }
      }

      .nft-sell {
        font-size: 0.8rem;
        text-align: center;
        padding: 1rem 0;
      }

      .nft-txt {
        padding: torem(15) 0 torem(10);
        font-size: torem(20);
        text-align: center;

        p {
          .nft-n {
            font-size: torem(24);
            font-weight: bold;
          }
        }
      }

      .btn-part {
        padding: 0 torem(25) torem(25);
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @include for-phone-only {
          flex-direction: column;
        }

        @include for-tablet-landscape-only {
          flex-direction: column;
        }

        a {
          cursor: pointer;
          padding: torem(15) torem(25);
          color: $dark;
          text-transform: uppercase;
          text-decoration: none;
          border-radius: 10px;
          font-size: torem(20);
          text-align: center;

          @include for-phone-only {
            font-size: torem(18);
          }

          &.btn-acquista {
            background-color: #4b7eef;
            color: $white;

            &.venduto {
              background-color: transparent;
              border: 1px dashed black;
              color: grey;
              cursor: no-drop;
            }

            &.sold-out {
              background-color: darkred;
            }
          }
        }
      }
    }
  }

  &--text {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: torem(20);
    align-items: center;
    padding: 0 torem(35);
    color: $white;
    z-index: 1;

    &-subtitle {
      font-size: torem(25);
      font-weight: bold;
      line-height: torem(25);
      margin-bottom: torem(5);

      &.barrato {
        font-size: torem(20);
        font-weight: normal;
        color: #d0d0d0;
        text-decoration: line-through;
        margin-right: torem(20);
      }
    }

    &-durata {
      font-size: torem(20);
      font-weight: 400;
    }
  }
}

.card-box.display .hover-play {
  position: relative;

  &:hover {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;

    &::after {
      content: '';
      width: calc(100% + 30px);
      left: -15px;
      height: 100%;
      background-image: url('/assets/img/play-circle-filled.svg');
      position: absolute;
      top: 0;
      z-index: 2;
      display: block;
      background-position: center 30%;
      background-size: 20%;
      background-repeat: no-repeat;
      opacity: .4;
    }
  }
}