.footer{
    width: 100%;
    padding-bottom: 0;
    .top-footer{
        background-color: #2B2B2B;
        color: $white;
        padding: torem(20) 0;
        @include for-phone-only{
            padding: torem(30) 0;
          }
        .social-bar{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: torem(30);
            @include for-phone-only{
                flex-direction: column;
              }
            .txt-part{
                @include for-phone-only{
                    margin-bottom: torem(30);
                  }
                .title-social{
                    text-transform: uppercase;
                    font-weight: 100;
                    font-size: torem(20);
                }
            }
            .social-link{
                .social-list{
                list-style: none;
                display: flex;
                align-items: center;
                gap: torem(20);
               margin-bottom: 0;
               padding-inline-start: 0;
                li{
                    font-size: 0;
                    a{
                        img{
                            width: 80%;
                            &:hover{
                                transform: scale(0.5);
                            }
                        }
                    }
                }
            }
            }
        }
    }
    .bottom-footer{
        background-color: black;
        color: $white;
        padding: torem(32) 0;
        .nav-footer{
            .footer-menu{
                display: flex;
                list-style: none;
                gap: torem(40);
                justify-content: center;
                align-items: center;
                margin: 0;
                margin-bottom: torem(45);
                padding-inline-start: 0;
                @include for-phone-only{
                    flex-direction: column;
                  }
                li{
                    a{
                        font-weight: 300;
                        font-size: torem(20);
                        color: $white;
                        text-decoration: none;
                        text-transform: uppercase;
                        @include for-phone-only{
                            font-size: torem(18);
                          }
                          &:hover{
                            font-weight: 500;
                          }
                    }
                }
            }
        }
        .copyright{
            font-size: torem(12);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: torem(20);
            a{
                font-weight: bold;
                color: #fff;
            }
        }
    }
}