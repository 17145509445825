@mixin for-phone-only {
  @media (max-width: torem(600)) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: torem(600)) and (max-width: torem(1100)) {
    @content;
  }
}

@mixin for-tablet-portait-only {
  @media (min-width: torem(600)) and (max-width: torem(899)) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: torem(900)) {
    @content;
  }
}

@mixin for-tablet-landscape-only {
  @media (min-width: torem(900)) and (max-width: torem(1199)) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: torem(1300)) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: torem(1200)) and (max-width: torem(1799)) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: torem(1800)) {
    @content;
  }
}

@mixin for-show-full-menu-sidebar-up {
  @media (min-width: torem(1000)) {
    @content;
  }
}

@mixin for-show-featured-one-column {
  @media (max-width: torem(1134)) {
    @content;
  }
}

@mixin for-show-featured-one-column-up {
  @media (min-width: torem(1135)) {
    @content;
  }
}

@mixin for-show-company-not-desktop {
  @media (max-width: torem(999)) {
    @content;
  }
}
