.modal-sec{
    padding: 0;
    .modal{
        .rarity{
            padding: torem(5) torem(15);
            text-transform: uppercase;
            color: #fff;
            border-radius: torem(2);
            font-size: torem(14);
            background-color: darkred;
            &.gold{
                background: #AF9500;
            }
            &.silver{
               background: #D7D7D7;
               color: #000;
            }
            &.bronze{
                background: #6A3805;
            }
                    }
      .modal-dialog{
        max-width: torem(700);
        box-shadow: 0 torem(3) torem(10) #0006;
        .modal-content{
            .close{
                position: absolute;
                width: torem(40);
                height: torem(40);
                border-radius: 100%;
                right: torem(-20);
                top: torem(-20);
                box-shadow: 0 torem(6) torem(20) #0003;
                border: 0;
                font-size: torem(38);
                font-weight: 100;
                @include for-phone-only{
                    right: 0;
                    top: 0;
                }
            }
            .modal-header{
                padding: torem(20) torem(35);
                .title{
                    .modal-title{
                        font-weight: torem(32);
                        font-weight: bold;
                    }
                    .modal-durata{
                        font-size: torem(20);
                        font-weight: normal;
                    }
                }
            }
            .modal-body{
                padding: 0;
                .modal-image{

                    img{
                        width: 100%;
                        height: torem(320);
                        object-fit: cover;
                    }
                }
                .modal-text{
                    padding: torem(35) torem(35) 0;
                    .paragraph{
                        margin-bottom: torem(20);
                        &:nth-child(2){
                            margin-bottom: 0;
                        }
                        .title-small{
                            font-size: torem(18);
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                        .text{
                            font-weight: normal;
                            line-height: torem(22);
                        }
                    }
                    .accordion{
                        padding: torem(30) 0;
                        .card-header{
                            position: relative;
                            border-bottom: 1px solid #DFDFDF;
                            .accordion-btn{
                                width: torem(20);
                                height: torem(20);
                                left: 50%;
                                position: absolute;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                border-radius: 50%;
                                background-color: #fff;
                                border: 1px solid #DFDFDF;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img{
                                    width: 60%;
                                    transform: rotate(180deg);
                                }
                                &.collapsed{
                                    img{
                                    transform: unset;
                                }
                                }
                            }
                        }
                        .card-body{
                            display: flex;
                            flex-direction: column;
                            gap: torem(5);
                            .item{
                                display: flex;
                                justify-content: space-between;
                            .int{
                               font-size: torem(16);
                               font-weight: 400;
                            }
                        }
                        }
                        .collapse{
                            margin-top: torem(20);
                        }
                    }
                }
            }
            .modal-footer{
                justify-content: space-between;
                padding: 0 torem(30) torem(20);
                border: 0;
                .btn-acquista{
                    padding: torem(15) torem(25);
                text-transform: uppercase;
                text-decoration: none;
                border-radius: 10px;
                font-size: torem(20);
                background-color: #4B7EEF;
                color: $white;
                &.venduto{
                    background-color: transparent;
                    border: 1px dashed black;
                    color: grey;
                    cursor: no-drop;
                }
                &.sold-out{
                    background-color: darkred;
                }
                }
                .price-sec{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    .price{
                        font-size: torem(16);
                        flex: 1;
                        // &.matic{
                        //     font-size: torem(20);
                        //     margin-bottom: torem(2);
                        //     flex: 0.6;
                        // }
                        &.eur {
                          font-size: torem(20);
                          margin-bottom: torem(2);
                          flex: 0.6;
                      }
                    }
                    p{
                        flex: 100%;
                    }
                }
            }
        }
      }
    }
}
