.card-cta{
    .img-part{
        img{
          object-fit: cover;
          border-radius: torem(25);
          width: 100%;
        }
    }
    &.version2{
      .img-part{
        position: relative;
        img{
          filter: brightness(60%);
                }
      }
      .text-part{
        position: absolute;
        bottom: torem(20);
        padding: 0 torem(50);
        .title-section{
          color: #fff;
        }
      }
    }
    .text-part{
        padding-left: torem(15); 
      .title-section{
        flex: 70%;
        color: $dark;
        .title{
           font-size: torem(100);
           font-weight: bold;
           @include for-phone-only{
            font-size: torem(40);
            margin-bottom: torem(16);
          }
        }
        .subtitle{
            font-size: torem(32);
            font-weight: 300;
            text-transform: uppercase;
            @include for-phone-only{
              font-size: torem(20);
              margin-bottom: torem(5);
            }
        }
      }  
      .cta{
        flex: 30%;
        justify-content: end;
        display: flex;
        align-items: self-end;
        padding-bottom: torem(25);
        @include for-phone-only{
          justify-content: start;
        }
      }
    }
}