.video-overlay {
    position: fixed;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .video-overlay iframe {
    width: calc(100% - 15rem);
    height: calc(100% - 15rem);
  }
  @media screen and (max-width: 800px) {
    .video-overlay iframe {
      width: calc(100% - 1rem);
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
    }
  }
  .video-overlay .box-close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    color: #000;
    background-color: #fff;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    font-size: 20px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    transform: scale(1);
    transition: 0.25s all ease;
  }
  .video-overlay .box-close:hover {
    transform: scale(1.2);
  }