.hero-detail {
    width: 100%;
    position: relative;
    display: flex;
    .bg-part {
        width: 100%;
        display: flex;
        .text-part {
            flex: 40%;
        }
    }
    .container {
        @include for-phone-only {
            padding: 0;
        }
    }
    @include for-phone-only {
        height: 75vh;
    }
    @include for-tablet-portrait-up {
        height: 40vh;
    }
    @include for-desktop-up {
        height: 80vh;
    }
    @include for-tablet-landscape-only {
        height: 80vh;
    }

    picture {
        flex: 0 0 60%;
        position: relative;
        display: flex;
        &::before {
            content: '';
            background-image: url(/assets/img/icon/detail-bg.svg);
            width: 100%;
            height: 100%;
            background-position: bottom left;
            position: absolute;
            background-size: cover;
            left: torem(-0.75);
            bottom: torem(-0.5);
            background-repeat: no-repeat;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @include for-desktop-only {
                height: 100%;
                object-fit: cover;
            }
            @include for-tablet-landscape-only {
                height: 100%;
                object-fit: cover;
            }
        }
        @include for-phone-only {
            flex: 0 0 100%;
        }
    }
    &--text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        @include for-phone-only {
            max-width: 100%;
            bottom: torem(20);
            top: unset;
            transform: none;
            width: 90%;
            left: torem(15);
        }
        &-paragrafo {
            font-size: torem(22);
            line-height: torem(32);
            margin-bottom: torem(50);
            max-width: torem(700);
            max-width: 50%;
            @include for-phone-only {
                font-size: torem(18);
                line-height: torem(25);
                margin-bottom: torem(35);
                padding-right: torem(5);
                max-width: 100%;
            }
            @include for-tablet-portrait-up {
                max-width: 80%;
                font-size: torem(20);
            }
        }
        &-title {
            font-size: torem(100);
            font-weight: bold;
            text-shadow: 0 torem(5) torem(8) #0006;
            margin-bottom: torem(10);
            @include for-phone-only {
                font-size: torem(60);
                line-height: torem(60);
            }
            @include for-tablet-portrait-up {
                font-size: torem(70);
                line-height: torem(50);
                margin-bottom: torem(25);
            }
        }
    }
}
