.rich-text {
    .box-blog {
        gap: torem(35);
        @include for-desktop-up {
            flex-wrap: nowrap;
            align-items: start;
        }
        @include for-phone-only {
            gap: torem(20);
            flex-direction: column-reverse;
        }
        @include for-tablet-portrait-up {
            flex-direction: column-reverse;
        }
        .img-sec {
            flex: 20%;
            display: flex;
            justify-content: space-between;
            @include for-desktop-up {
                flex-direction: column;
                justify-content: start;
                gap: torem(60);
            }
            @include for-phone-only {
                justify-content: center;
                display: flex;
                flex-wrap: wrap;
                flex: 100%;
                gap: torem(30);
            }
            img {
                border-radius: torem(20);
                border: torem(3) solid #d0d0d0;
                padding: torem(10);
                width: torem(330);
                &:last-child {
                    margin-bottom: 0;
                }
                @include for-phone-only {
                    width: 100%;
                }
            }
        }
        .txt-sec {
            flex: 80%;
            .title-detail {
                font-size: torem(24);
                margin-bottom: torem(20);
            }
            .title-section {
                font-size: torem(40);
                line-height: torem(38);
                font-weight: 400;
                margin-bottom: torem(40);
            }
            .txt-section {
                margin-bottom: torem(20);
                font-size: torem(18);
                line-height: torem(32);
            }
        }
        .box-item {
            a {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    top: 50%;
                    left: 50%;
                    background-image: url(/assets/img/icon/play-btn.svg);
                    transform: translate(-50%, -50%);
                    &:hover {
                        width: 55px;
                        height: 55px;
                    }
                }
            }
        }
    }
    @media (min-width: 1200px) {
        .container {
            max-width: torem(1140);
        }
    }
}
